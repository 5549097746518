<template>
  <router-link :to="{name: 'tournaments_id', params: {id: getTournamentId}}" target="_blank" class="event-card">
    <div class="event-card__head">
      <img class="event-img" :src="getBackground" alt="#">
      <div class="event-discipline">
        <!--        <img :src="getLogo" alt="#">-->
      </div>
    </div>
    <div class="event-card__body">
      <div class="event-card__desc">
        <div class="name">{{ getName }}</div>
        <div class="time">
          <span class="s1">Начнётся</span>
          <span class="s2">
            <vue-countdown-timer
                :start-time="getStartedTime"
                :end-time="getEndedTime"
                :seconds-txt="null"
                :day-txt="null"
                @end_callback="updateTournaments"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="event-card__foot">
      <div class="pentagon">
        <div class="text">
          <div class="s1">игроков</div>
          <div class="s2">{{ getCurrentPlayers }} / {{ getCountOfTeams }}</div>
        </div>
      </div>

      <div class="pentagon">
        <div class="text">
          <div class="s1">приз</div>
          <div class="s2">{{ getPrizeFund }}</div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "event-card",
  props: {
    eventCard: Object
  },

  computed: {
    ...mapGetters([
      'GAMES'
    ]),
    getLogo() {
      return this.GAMES.find(el => String(el.id) === String(this.eventCard.discipline_id)).logo
    },
    getTournamentId() {
      return this.eventCard.id
    },

    getBackground() {
      return this.eventCard.background
    },

    getName() {
      return this.eventCard.attributes.name
    },

    getStartedTime() {
      return this.eventCard.attributes['current_timestamp']
    },

    getEndedTime() {
      return (moment(this.eventCard.attributes['started_at']).unix());
    },

    getPrizeFund() {
      const prizeFund = this.eventCard.attributes['prize_fund'];
      if (prizeFund.length >= 10) return `${prizeFund.slice(0, 10)}...`
      return prizeFund
    },

    getCurrentPlayers() {
      return this.eventCard.attributes['participants_count']
    },

    getCountOfTeams() {
      return this.eventCard.attributes['count_of_teams']
    }
  },

  methods: {
    ...mapActions([
      'SET_THREE_MAIN_UPCOMING_EVENTS_ACTION'
    ]),

    async updateTournaments() {
      await this.SET_THREE_MAIN_UPCOMING_EVENTS_ACTION()
    }
  },
}
</script>

<style scoped lang="scss">
@import "src/scss/vars/colors";
@import "src/scss/vars/mixins";

.event-card {
  background: linear-gradient(158.58deg, #464646 -10.58%, #272727 108.91%);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
  //max-width: 368px;
  height: 400px;
  //width: 100%;
  flex-basis: calc(33.33333333% - 16px);
  margin: 8px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  transition: box-shadow .2s ease-in-out;
  font-family: $blatant-cyrillic, $blatant-latin;
  display: block;
  @media (max-width: 1919px) {
    height: dynamic-vw(1920, 400px);
  }
  @media (max-width: 1024px) {
    max-width: unset;
    min-width: 368px;
    flex-basis: unset;
    width: 100%;
    height: 400px;
  }
  @media (max-width: 525px) {
    width: auto;
    min-width: unset;
    max-width: unset;
    height: 215px;
    margin-left: 0;
    margin-right: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &:hover {
    box-shadow: 0 16px 20px rgba(17, 17, 17, 0.15);

    .name {
      color: $yellow;
    }
  }

  &__foot {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding-left: 24px;
    padding-right: 24px;
    @media (max-width: 1919px) {
      padding-left: dynamic-vw(1920, 24px);
      padding-right: dynamic-vw(1920, 24px);
      bottom: dynamic-vw(1920, 30px);
    }
    @media (max-width: 1024px) {
      padding-left: 24px;
      padding-right: 24px;
      bottom: 30px;
    }
    @media (max-width: 525px) {
      bottom: 16px;
    }

    .pentagon {
      flex-basis: 33%;
      height: 51px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      @media (max-width: 525px) {
        height: unset;
      }


      .text {
        position: relative;
        z-index: 1;
        font-size: 14px;
        line-height: 1.2;
        @media (max-width: 1919px) {
          font-size: dynamic-vw(1920, 14px);
        }
        @media (max-width: 1024px) {
          font-size: 14px;
        }
        @media (max-width: 525px) {

        }

        .s1 {
          font-weight: normal;
          text-transform: uppercase;
          color: transparentize(#FFFFFF, .4);
          margin-bottom: 3px;
          text-align: center;
          @media (max-width: 525px) {
            font-size: 10px;
          }
        }

        .s2 {
          font-weight: 600;
          color: $concrete;
          @media (max-width: 525px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  &__desc {
    padding: 24px;
    @media (max-width: 1919px) {
      padding: dynamic-vw(1920, 24px);
    }
    @media (max-width: 1024px) {
      padding: 24px;
    }
    @media (max-width: 525px) {
      padding: 16px;
    }

    .name {
      color: $concrete;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: 1px;
      text-transform: uppercase;
      transition: color .2s ease-in-out;
      margin-bottom: 8px;
      @include line-clamp(4, 100%);
      @media (max-width: 1919px) {
        font-size: dynamic-vw(1920, 20px);
      }
      @media (max-width: 1024px) {
        font-size: 20px;
      }
      @media (max-width: 525px) {
        font-size: 16px;
        @include line-clamp(2, 100%)
      }
    }

    .sponsor {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $yellow;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 24px;

      svg {
        margin-left: 4px;
      }
    }

    .time {
      display: flex;
      flex-direction: row;
      font-family: $blatant-cyrillic, $blatant-latin;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.2;
      @media (max-width: 1919px) {
        font-size: dynamic-vw(1920, 14px);
      }
      @media (max-width: 1024px) {
        font-size: 14px;
      }
      @media (max-width: 525px) {
        align-items: flex-start;
        font-size: 12px;
      }

      .s1 {
        margin-right: 8px;
        text-transform: uppercase;
        color: #B0B0B0;
      }

      .s2 {
        color: #FFFFFF;
      }
    }
  }

  &__body {
    width: 100%;
    position: relative;

    .sprayL, .sprayR {
      position: absolute;

    }

    .sprayL {
      bottom: 0;
      left: 0;
    }

    .sprayR {
      //top: 0;
      right: 0;
      bottom: 30px;
    }
  }

  &__head {
    height: 160px;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 1919px) {
      height: dynamic-vw(1920, 160px);
    }
    @media (max-width: 1024px) {
      height: 160px;
    }
    @media (max-width: 525px) {
      height: 90px;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: '';
      background: linear-gradient(0deg, rgba(39, 39, 39, 0.5), rgba(39, 39, 39, 0.5));
      z-index: 1;
    }

    .event-img {
      @include centerImg;
    }

    .event-discipline {
      height: 50%;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
}

</style>
