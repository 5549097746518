import * as THREE from 'three'
import { TweenMax as TM } from 'gsap'
import vertexShader from '../../glsl/vertexShader.glsl'
import fragmentShader from '../../glsl/fragmentShader.glsl'

export default class Figure {
    constructor(scene, cb) {
        this.$hover_image = document.querySelector('.hover__image')
        this.scene = scene
        this.callback = cb

        this.loader = new THREE.TextureLoader()
        this.image = this.loader.load(this.$hover_image.src, () => {
            this.start()
        })
        this.sizes = new THREE.Vector2(0, 0)
        this.offset = new THREE.Vector2(0, 0)

        this.mouse = new THREE.Vector2(0, 0)
        window.addEventListener('mousemove', ev => {
            this.onMouseMove(ev)
        })
        this.setupResize()

    }

    start() {
        this.getSizes()
        this.createMesh()
        this.callback()
    }

    getSizes() {
        const { width, height, top, left } = this.$hover_image.getBoundingClientRect()
        this.sizes.set(width, height)
        this.offset.set(
            left - window.innerWidth / 2 + width / 2,
            -top + window.innerHeight / 2 - height / 2
        )
    }

    createMesh() {
        this.uniforms = {
            u_imagehover: { type: 't', value: this.image },
            u_mouse: { value: this.mouse },
            u_time: { value: 0 },
            u_res: {
                value: new THREE.Vector2(window.innerWidth, window.innerHeight)
            },
        }

        this.geometry = new THREE.PlaneBufferGeometry(1, 1, 15, 15)
        this.material = new THREE.ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            defines: {
                PR: window.devicePixelRatio.toFixed(1)
            },
            // wireframe: true
        })

        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.set(this.offset.x, this.offset.y, 0)
        this.mesh.scale.set(this.sizes.x, this.sizes.y, 1)

        this.scene.add(this.mesh)
    }

    onMouseMove(event) {
        TM.to(this.mouse, 0.5, {
            x: (event.clientX / window.innerWidth) * 2 - 1,
            y: -(event.clientY / window.innerHeight) * 2 + 1
        })
    }

    update() {
        if (this.scene === null) return
        this.uniforms.u_time.value += 0.01
    }

    updateMesh() {
        this.getSizes()
        this.createMesh()
    }

    setupResize() {
        window.addEventListener("resize", this.resize.bind(this));
    }

    resize() {
        if (this.scene === null) return
        this.destroyMesh();
        this.updateMesh();
    }

    destroyMesh() {
        this.mesh.parent.remove(this.mesh);
        this.geometry.dispose();
        this.material.dispose();
    }

    destroy() {
        window.removeEventListener('resize', this.resize.bind(this))
        this.loader = null;
        this.image = null;
        this.destroyMesh();
        this.mesh = null;

        this.start = null
        this.resize = null
        this.callback = null
    }
}
