<template>
  <div class="home">

    <div class="home-hero">

      <div class="container">
        <div class="home-hero-content">
          <div class="home-svg-title">
            <svg width="612" height="129" viewBox="0 0 612 129" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M116.995 52.8415H66.5037C32.1128 52.7521 30.2656 46.1324 30.2656 38.2468C30.2656 30.2868 40.2308 22.5793 60.4094 22.5793C60.8624 22.5793 110.853 22.5869 111.306 22.5945C131.017 22.9887 150.259 31.8872 150.259 31.8872L158.427 10.9851C158.427 10.9851 140.693 0.0686587 111.906 0.0686587C111.439 0.0686587 61.4203 0.0686354 60.9604 0.0761935C29.5563 0.463549 7.66506 13.9492 7.66506 41.4042C7.66506 64.5327 24.3686 75.2999 54.9515 75.2999H105.381C135.22 75.4045 142.425 80.9447 142.425 91.1236C142.425 101.177 130.832 106.146 111.177 106.366C110.724 106.372 60.7198 106.372 60.2523 106.372C39.7552 106.372 9.80123 92.1628 9.80123 92.1628L0 112.797C0 112.797 29.1605 128.436 60.6852 128.984C61.2368 128.99 111.34 128.996 111.9 128.996C144.054 128.996 164.785 113.666 164.785 91.4429C164.785 69.22 148.032 52.8415 116.995 52.8415ZM441.315 66.4091V87.4673H473.552C467.438 99.7531 455.193 106.867 435.082 106.971H243.503C212.644 106.971 199.712 90.997 199.712 64.495C199.712 37.9929 212.643 22.0282 243.503 22.0282H435.044C468.481 22.1473 481.393 36.1998 481.393 36.1998L498.162 22.1706C498.162 22.1706 479.269 0.181396 435.126 0C434.956 0.00755815 243.688 0.00757322 243.51 0.00757322C202.034 0.00757322 175.132 28.8772 175.132 64.5038C175.132 100.13 202.034 129 243.51 129H435.051C452.749 128.912 467.753 123.539 479.125 114.652V127.073H502.987V66.4091H441.315ZM544.585 1.87189H520.717V126.988H612V105.926H544.585V1.87189Z"
                    fill="#E2FF31"/>
              <path d="M315.642 47.3089L298.76 30.3849L247.67 81.6023L264.552 98.5263L315.642 47.3089Z" fill="white"/>
              <path d="M298.76 98.5263L315.642 81.6023L264.552 30.3849L247.67 47.3089L298.76 98.5263Z" fill="white"/>
              <path d="M408.002 47.3089L391.12 30.3849L340.03 81.6023L356.911 98.5263L408.002 47.3089Z" fill="white"/>
              <path d="M391.12 98.5263L408.002 81.6023L356.912 30.3849L340.03 47.3089L391.12 98.5263Z" fill="white"/>
            </svg>

          </div>
          <div class="home-hero-desc"
               v-html="'Регистрируйтесь и принимайте участие в турнирах на реальные призы.'">
          </div>
          <div class="home-hero-button" v-if="!AUTH.isAuthenticate" @click="SHOW_USER_AUTH_MODALS({isAuth: true, modal: 'register'})">
            <div class="home-hero-button__inner">
              Создать аккаунт
            </div>
          </div>
        </div>
      </div>
      <div class="bgi">
        <media :query="{minWidth: 769}">
          <img class="hover__image" src="~@/img/hero-bg.jpg" alt="#">
        </media>
        <canvas ref="stage" id="stage"></canvas>
      </div>
    </div>

    <div class="home-section first-section black-section">
      <img class="main-sprite" src="~@/img/mainSprite.png" alt="">
      <div class="container">
        <div class="section-title">
          <div class="name">Игры</div>
          <router-link to="/tournaments" class="button">
            <span>смотреть все</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 5L16 12L9 19" stroke="#E2FF31" stroke-width="1.5"/>
            </svg>
          </router-link>
        </div>
        <div class="section-content">
          <div class="section-content-l">
            <div class="home-text">Испытай себя в игре</div>
          </div>
          <div class="section-content-r">
            <media :query="{minWidth: 526}">
              <div class="cards-list">
                <game-card v-for="disciplineCard in disciplineCards"
                           :key="disciplineCard.id"
                           :props="disciplineCard"
                />
              </div>
            </media>
            <media :query="{maxWidth: 525}">
              <div class="mobile-list">
                <mobile-card v-for="disciplineCard in disciplineCards"
                             :key="disciplineCard.id"
                             :props="disciplineCard"
                />
              </div>
            </media>
          </div>
        </div>
      </div>
    </div>
    <div class="home-section black-section" v-if="isHaveThreeMainUpcomingEvents">
      <div class="container">
        <div class="section-title">
          <div class="name">Ближайшие события</div>
          <router-link to="/tournaments" class="button">
            <span>смотреть все</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 5L16 12L9 19" stroke="#E2FF31" stroke-width="1.5"/>
            </svg>
          </router-link>
        </div>
        <div class="section-content">
          <div class="section-content-l">
            <div class="home-text">Выбери турнир и поборись за призы</div>
          </div>
          <div class="section-content-r">
            <div class="cards-list">
              <event-card v-for="eventCard in getThreeMainUpcomingEvents"
                          :key="eventCard.id"
                          :eventCard="eventCard"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-section transparent-section">
      <div class="container">
        <div class="section-content">
          <div class="section-content-l">
            <div class="greetings">
              <media :query="{minWidth: 526}">
                <span v-html="'добро<br>пожаловать в'"></span>
              </media>
              <media :query="{maxWidth: 525}">
                <span v-html="'добро пожаловать в'"></span>
              </media>
              <svg width="270" height="59" viewBox="0 0 270 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M51.6149 24.1686H29.3396C14.1673 24.1277 13.3523 21.0996 13.3523 17.4924C13.3523 13.8513 17.7487 10.3256 26.6509 10.3256C26.8507 10.3256 48.9051 10.3291 49.105 10.3325C57.801 10.5129 66.2902 14.5834 66.2902 14.5834L69.8935 5.02204C69.8935 5.02204 62.0696 0.0284771 49.3697 0.0284771C49.1638 0.0284771 27.0969 0.0284664 26.894 0.0319238C13.0394 0.209113 3.3816 6.3779 3.3816 18.9367C3.3816 29.5165 10.7507 34.4418 24.243 34.4418H46.4909C59.6553 34.4896 62.834 37.0239 62.834 41.6801C62.834 46.2786 57.7192 48.5518 49.0482 48.6524C48.8483 48.6553 26.7878 48.6553 26.5816 48.6553C17.5389 48.6553 4.32402 42.1554 4.32402 42.1554L0 51.594C0 51.594 12.8648 58.7479 26.7726 58.9985C27.0159 59.0014 49.1202 59.0043 49.3669 59.0043C63.5525 59.0043 72.6983 51.9916 72.6983 41.8261C72.6983 31.6606 65.3076 24.1686 51.6149 24.1686ZM194.695 30.3748V40.0076H208.917C206.221 45.6275 200.818 48.8814 191.946 48.9293H107.427C93.8125 48.9293 88.107 41.6222 88.107 29.4992C88.107 17.3763 93.812 10.0735 107.427 10.0735H191.929C206.681 10.128 212.377 16.5561 212.377 16.5561L219.775 10.1386C219.775 10.1386 211.44 0.0800468 191.965 -0.00292969C191.89 0.000527665 107.508 0.000534558 107.43 0.000534558C89.1318 0.000534558 77.2632 13.2065 77.2632 29.5033C77.2632 45.8001 89.1318 59.006 107.43 59.006H191.932C199.74 58.9657 206.359 56.5081 211.376 52.4428V58.1244H221.903V30.3748H194.695ZM240.256 0.853337H229.725V58.0855H269.997V48.451H240.256V0.853337Z"
                        fill="#E2FF31"/>
                  <path
                      d="M131.811 13.8933L139.259 21.6349L131.713 29.4783L139.259 37.3218L131.812 45.0634L124.266 37.2199L116.72 45.0634L109.272 37.3218L116.818 29.4783L109.272 21.6349L116.72 13.8933L124.266 21.7367L131.811 13.8933Z"
                      fill="white"/>
                  <path
                      d="M172.554 13.8933L180.001 21.6349L172.456 29.4783L180.001 37.3218L172.554 45.0634L165.008 37.2199L157.462 45.0634L150.014 37.3218L157.56 29.4783L150.014 21.6349L157.462 13.8933L165.008 21.7367L172.554 13.8933Z"
                      fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="270" height="59" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="section-content-r">
            <div class="page-description">
              <div class="text-block">
                <div class="title">О нас</div>
                <div class="home-text">
                  Мы помогаем игрокам не только проникнуться духом соревнования, но и выйти на профессиональный уровень.
                  Благодаря нашему бренду игроки испытывают эмоции, недоступные в повседневной жизни. Каждый получает
                  шанс проявить себя!
                </div>
              </div>
              <div class="home-hero-button" v-if="!AUTH.isAuthenticate" @click="SHOW_USER_AUTH_MODALS({isAuth: true, modal: 'register'})">
                <div class="home-hero-button__inner">
                  Создать аккаунт
                </div>
              </div>
              <div class="row">
                <div class="text-block text-block__short">
                  <div class="title">Игровые лиги</div>
                  <div class="home-text">Участвуй в наших сериях турниров и покажи кто лучший на дистанции.</div>
                </div>
                <div class="text-block text-block__short">
                  <div class="title">Реальные призы</div>
                  <div class="home-text">Выбери турнир и поборись за главный приз.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <login-modal
        v-if="AUTH.isAuthOpen"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Media from 'vue-media'

import GameCard from "../components/home/game-card";
import EventCard from "../components/home/event-card";
import LoginModal from "@/components/modals/login-modal";
import Scene from "@/otherJS/home-webgl/Scene";
import MobileCard from "@/components/home/mobile-card";

export default {
  name: 'home',
  components: {MobileCard, LoginModal, EventCard, Media, GameCard},
  data() {
    return {
      disciplineCards: [
        {name: 'NHL21', imgName: 'nhl21.png', id: 3},
        {name: 'FIFA21', imgName: 'fifa21.png', id: 1},
        {name: 'UFC4', imgName: 'ufc4.png', id: 2},
        {name: 'NBA2K21', imgName: 'nba21.png', id: 5},
      ],
      filterX: 'center',
      filterY: 'center',
      canvas: null
    }
  },
  computed: {
    ...mapGetters([
      'AUTH',
      'GET_THREE_MAIN_UPCOMING_EVENTS'
    ]),

    getThreeMainUpcomingEvents() {
      try {
        return this.GET_THREE_MAIN_UPCOMING_EVENTS.data
      } catch (e) {
        return []
      }
    },

    isHaveThreeMainUpcomingEvents() {
      return this.getThreeMainUpcomingEvents.length !== 0
    }
  },
  methods: {
    ...mapActions([
      'GET_SECRET',
      'SHOW_USER_AUTH_MODALS',
      'SET_THREE_MAIN_UPCOMING_EVENTS_ACTION',
      'GET_DISCIPLINES'
    ]),
    initWebGL() {
      if (window.innerWidth > 768) {
        let gl = null;
        try {
          // Попытаться получить стандартный контекст. Если не получится, попробовать получить экспериментальный.
          gl = this.canvas.getContext("webgl") || this.canvas.getContext("experimental-webgl");
        } catch (e) {
          console.log(e)
        }
        // Если мы не получили контекст GL, завершить работу
        if (!gl) {
          gl = null;
        }

        return gl;
      }
      return null
    },
  },
  async mounted() {
    if (window.innerWidth > 768) {
      this.canvas = this.$refs.stage

      this.$nextTick(function () {
        if (this.initWebGL()) {
          window.scene = new Scene()
        }
      });
    }

    await this.GET_DISCIPLINES()

    await this.SET_THREE_MAIN_UPCOMING_EVENTS_ACTION()

  },
  destroyed() {
    if (window.innerWidth > 768) {
      window.scene.destroy();
      window.scene = null;
      this.canvas = null
    }
  }
}
</script>
