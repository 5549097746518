<template>
  <div class="mobile-card">
    <div class="mobile-card__square">
      <img class="mobile-card__bg" :src="require(`@/img/main/mobile-card/${props.imgName}`)" alt="#">
    </div>
    <div class="mobile-card__name">{{ props.name }}</div>
  </div>
</template>

<script>
export default {
  name: "mobile-card",
  props: {
    props: {}
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/vars/colors";
@import "src/scss/vars/mixins";

.mobile-card {
  width: fit-content;
  display: block;
  font-family: $blatant-latin, $blatant-cyrillic;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 1px;
  color: #FFFFFF;
  @media (max-width: 525px) {
    margin-right: 8px;
  }

  &__square {
    width: 110px;
    height: 110px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    background: linear-gradient(324.72deg, #2A3103 -20.92%, #E5FF4D 56.3%, #A4B735 112.35%);
    box-shadow: 0 11px 20px rgba(17, 17, 17, 0.4);
    z-index: 0;
  }

  &__bg {
    @include centerImg;
    width: 94%;
    height: 94%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 18px;
    pointer-events: none;
  }

  &__name {
    margin-top: 3px;
    position: relative;
    z-index: 1;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

</style>