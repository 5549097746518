<template>
  <router-link :to="{ name: 'allTournaments', params: { game_id: props.id }}" class="game-card">
    <img class="game-card__bg" :src="require(`@/img/main/${props.imgName}`)" alt="#">
    <img class="game-card__logo" :src="require(`@/img/main/logo/${props.imgName}`)" alt="#">
    <!--    <div class="game-card__label">
          <div class="text">{{ props.name }}</div>
        </div>-->
  </router-link>
</template>

<script>
export default {
  name: "game-card",
  props: {
    props: {}
  }
}
</script>

<style scoped lang="scss">
@import 'src/scss/vars/mixins';

.game-card {
  background: linear-gradient(158.58deg, #464646 -10.58%, #272727 108.91%);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
  //height: 418px;
  //min-width: 272px;
  flex-basis: calc(25% - 16px);
  height: 407px;
  margin: 8px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  display: block;
  @media (max-width: 1919px) {
    height: dynamic-vw(1920, 407px);
  }
  @media (max-width: 1024px) {
    height: 418px;
    flex-basis: unset;
    min-width: 272px;
  }


  &__label {
    text-align: center;
    left: 50%;
    transform: translateX(-50%) translateY(-24px);
    position: absolute;
    width: fit-content;
    min-width: 240px;
    max-width: 150%;
    height: 70px;
    z-index: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: 1px;
    color: #EAEAEA;
    background: linear-gradient(283.54deg, #2A3103 7.09%, #E8F985 45.09%, #A4B735 103.09%);

    display: flex;
    align-items: flex-end;
    justify-content: center;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    padding-bottom: 3px;

    .text {
      position: relative;
      z-index: 1;
      padding-bottom: 3px;
      width: 100%;
      padding-left: 20%;
      padding-right: 20%;
    }

    &:after {
      position: absolute;
      min-width: calc(100% - 6px);
      height: calc(100% - 6px);
      content: '';
      z-index: 0;
      background: linear-gradient(180deg, #272727 0%, #464646 100%);
      clip-path: polygon(1% 0, 99% 0, 80% 100%, 20% 100%);
    }
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }

  &__spray {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 100%;
  }

  &__logo {
    position: absolute;
    z-index: 1;
    width: 80%;
    display: block;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
  }

}
</style>
